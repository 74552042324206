<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <h2 class="text-primary mb-1">
        {{ typeof $route.meta.pageTitle === 'function' ? $route.meta.pageTitle() : $route.meta.pageTitle }}
      </h2>
      <div class="d-flex align-items-center">
        <b-button class="ml-1" variant="dark" @click.prevent="$router.push({ name: 'jenisacara' })">
          <feather-icon icon="ListIcon" />
          <span class="d-none d-md-inline">Back To List</span>
        </b-button>
      </div>
    </div>

    <vue-form-json-schema v-model="modelData" :schema="schema" :ui-schema="uiSchema" />

    <b-button class="d-flex align-items-center mt-50" variant="success" @click.prevent="handleSubmit">
      <feather-icon class="mr-50" icon="SaveIcon" />
      Save
    </b-button>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import uiSchema from './schema/FormRequestUiSchema.json'
import schema from './schema/FormRequestSchema.json'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
  },
  data() {
    return {
      modelData: {
        jenisacara: ''
      },
      idJenisAcara: 0,
      schema,
      uiSchema,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getDetailData()
    }
  },
  methods: {
    handleSubmit() {
      if (this.$route.params.id) {
        this.handleEdit()
      } else {
        this.handleCreate()
      }
    },

    handleCreate() {
      if (!this.validateForm()) {
        return
      }

      const data = {
        ...this.modelData,
      }
      this.$http.post('/api/jenisacara/create', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/jenisacara')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    handleEdit() {
      if (!this.validateForm()) {
        return
      }
      const data = {
        kodejenisacara: this.data.idJenisAcara,
        jenisacara: this.modelData.jenisacara
      }
      this.$http.post('/api/jenisacara/edit', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menyimpan data',
          },
        });

        setTimeout(() => {
          this.$router.push('/jenisacara')
        }, 1000)
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal menyimpan data' + e,
          },
        });
      })
    },

    getDetailData() {
      this.$http.get(`/api/jenisacara/detail/${this.$route.params.id}`).then(res => {
        const response = res.data.data
        this.data = { idJenisAcara: response.kodejenisacara }
        this.modelData = {
          jenisacara: response.jenisacara,
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
      })
    },

    validateForm() {
      if (this.modelData.jenisacara === '' || this.modelData.jenisacara === null) {
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Silakan mengisi nama jenisacara',
          },
        });
        return false;
      }

      return true
    }
  }
}
</script>
